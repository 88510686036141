<template>
  <main-document :tabs="tabs"> </main-document>
</template>

<script>

import tabController from '@/tabController'

export default {
  components: {
    MainDocument: () => import('@/components/main-document')
  },
  deactivated () {
    this.$removeFromKeepAliveCache()
    this.$destroy()
  },
  activated () {
    this.$store.commit('setModule', {
      name: this.$t('t.Topic'),
      icon: 'i.Topics'
    })
  },
  computed: {
    tabs () {
      const documentId = this.$route.params.id !== 'undefined' ? this.$route.params.id : undefined
      return [tabController.create(tabController.topicsTab(), { documentId })]
    }
  }
}
</script>
